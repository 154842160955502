let api = process.env.REACT_APP_MKGF_MASTODON_API+'/forum'

export const getAllFourms = async (token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/all" , { headers });
    const data = await response.json();
    return data
}

export const searchAllForums = async ({token, title, page_number, category, limit}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/search?title="+title+"&page_number="+page_number+"&limit="+limit+"&category="+(category ?? ''), { headers });
    const data = await response.json();
    return data
}

export const getForum = async (token, id) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { headers });
    const data = await response.json();
    return data
}

export const createForum = async ({token, status, category, author, title}) => {
    let body = {status, category, author, title}
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/" , { method:"POST", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const editForum = async ({token, id, body}) => {
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/"+id , { method:"PUT", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const deleteForum = async ({token, id, status_id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id+"/"+status_id , { method:"DELETE", headers });
    const data = await response.json();
    return data
}