import { ImageListItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export const colors = ["#E40303", "#FF8C00", "rgb(170, 159, 5)", "#008026", "#24408E", "#732982", "rgb(192, 97, 127)", "rgb(77, 179, 202)", "#613915", "#000000"]

export const MediaCard = ({item}) => {
    let navigate = useNavigate()

    let placeholder_img = 'https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/logo.svg'

    if(!item?.img){
        return (
            <div>
                No media
            </div>
        )
    }
    return (
        <ImageListItem onClick={() => item?.id && navigate("/post/"+item?.id)} style={{backgroundColor:colors[Math.floor(Math.random()*colors.length)], display:'flex', placeContent:'center'}}>
            {item?.img.includes("mp4") ?
            <div style={{placeContent:'center', display:'flex'}}>
                <video controls style={{height:'150px'}}>
                <source src={item?.img} type="video/mp4" />
                </video>
            </div>
            :
            item?.img.includes("https://storage.googleapis.com") ?
            <img
                srcSet={`${item?.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item?.img}?w=164&h=164&fit=crop&auto=format`}
                alt={'Upload Error'}
                loading="lazy"
                onLoad={e => {
                    if(e.target.height < 10) e.target.src = placeholder_img
                }}
                onError={(e) => {
                    e.target.style.textAlign = 'center';
                    e.target.style.background = 'url('+placeholder_img+')';
                    e.target.style.backgroundSize = 'contain';
                    e.target.style.backgroundRepeat = 'no-repeat';
                    if(e.currentTarget.src !== placeholder_img) 
                        e.target.src = placeholder_img
                    }}
            />
            :
            <div style={{ color:'white', width:'80%', height:'170px', overflow: 'hidden', alignContent:'center', margin:'auto'}}>
                {item.id ?
                    <Typography style={{fontSize:"12px", }}>{item?.img.replace(/(<([^>]+)>)/ig, '').replaceAll("&#39;", "'").slice(0,70)}{item?.img?.length > 70 && "..."}</Typography>
                    :
                    <Typography style={{fontSize:"12px", }}>{item?.img.replace(/(<([^>]+)>)/ig, '').replaceAll("&#39;", "'")}</Typography>
                }
            </div>
            }
        </ImageListItem>
    )
}