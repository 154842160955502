import { QueryClient, useMutation } from "@tanstack/react-query"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useContext, useEffect, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { Button, Card, CardActions, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Grid, List, ListItem, Radio, RadioGroup, TextField } from "@mui/material"
import { PostMedia } from "../../components/post/card/PostMedia"
import { useNavigate } from "react-router"
import { createEvent } from "../../controllers/event/event"
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { createComment } from "../../controllers/posts/comments"
import { CategoryPicker } from "../../components/category.picker"
import Autocomplete from "react-google-autocomplete";
import { header_radio_set } from "../../util/util"

export const CreateEvent = () => {
    let YOUR_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_YOUR_GOOGLE_MAPS_API_KEY
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const [header_img, setHeaderImg] = useState('')
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const [chooseHeader, setChooseHeader] = useState(true)
    const [startdate, setStartDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const [category, setCategory] = useState('')
    const [status, setStatus] = useState('')
    const [location, setLocation] = useState('')
    let navigate = useNavigate()

    let field_set = [
        {id:"title", value:"", setValue:() => {}, label:"Title"},
    ]

    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: createEvent,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createEvent'] })
        navigate("/event/show/"+data.event._id)
        },
    })

    useEffect(() => {
      setHeaderImg(imageUrls[imageUrls.length - 1])
    }, [imageUrls])

    useEffect(() => {
      setHeaderImg('https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/community-1.jpg')
    }, [])
    
    const sendCreateEvent = (status_id) => {
        let title = document.getElementById("title").value
        
        mutation.mutate({
          title,
          desc:status,
          start_date_time:startdate,
          end_date_time:enddate,
          location,
          status:status_id,
          header_img,
          category,
          author:{id:userDetails?.id, avatar:userDetails?.avatar, username:userDetails?.acct},
          token:userDetails?.token
        })
    }

    const mutationComment = useMutation({
      mutationFn: createComment,
      onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createComment'] })
        sendCreateEvent(data.id)
      },
    })
  
    const send_reply = () => {
        mutationComment.mutate({
        body:{
            status,
            visibility:"unlisted",
            media_ids
        },
        token
      })
    }

    return (
        <MainLoggedInLayout>
            <Card>
                <CardHeader
                    title="Create an event!"
                    subheader={new Date().toDateString()}
                />
                <CardContent>
            
                    <List>
                    
                        {field_set.map((field, i) =>
                            <ListItem key={i}>
                                <TextField fullWidth id={field.id} onChange={(e) => field.setValue(e.target.value)} label={field.label} variant="outlined" />
                            </ListItem>
                        )}

                        <ListItem>
                            {chooseHeader ?
                            <Button variant="contained" onClick={() => setChooseHeader(false)}>Upload Event Image</Button>
                            :
                            <Button variant="contained" onClick={() => setChooseHeader(true)}>Choose Event Image</Button>
                            }
                        </ListItem>

                        <ListItem>- OR -</ListItem>

                        {chooseHeader ?
                        <ListItem>

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Choose Event Image</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={header_img}
                                    onChange={(e) => {
                                        setHeaderImg(e.target.value)}}
                                >
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {header_radio_set.map((radio, index) =>
                                        <Grid item xs={4} sm={4} md={6} key={index}>
                                            <FormControlLabel value={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} control={<Radio />} label={<img alt={"community pics"} src={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} width={150} height={70} />} />
                                        </Grid>
                                        )}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>

                        </ListItem>
                        :
                        <ListItem>
                        <FormLabel id="demo-row-radio-buttons-group-label">Upload Event Image</FormLabel>

                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={userDetails?.token} />
                        </ListItem>
                        }

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={4} sm={4} md={6}>
                                <ListItem>
                                    Start Date And Time
                                </ListItem>

                                <ListItem>
                                    <Datetime value={startdate} onChange={(value) => setStartDate(value._d)} />
                                </ListItem>
                            </Grid>

                            <Grid item xs={4} sm={4} md={6}>
                                <ListItem>
                                    End Date And Time
                                </ListItem>

                                <ListItem>
                                    <Datetime value={enddate} onChange={(value) => setEndDate(value._d)} />
                                </ListItem>
                            </Grid>
                        </Grid>
                        
                        <ListItem>
                            
                            <Autocomplete
                                className="form-control"
                                placeholder="Enter Address"
                                apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                onPlaceSelected={(place) => {
                                    setLocation(place)
                                }}
                                options={{
                                  types: ["(regions)"],
                                  componentRestrictions: { country: "us" },
                                }}
                            />

                        </ListItem>

                        <div style={{marginLeft:20, marginRight:20}}>
                            <TextField value={status} onChange={(e) => setStatus(e.target.value)} fullWidth multiline id="outlined-basic" label='Description' variant="outlined" />
                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={token} />
                        </div>

                        <ListItem>
                            <CategoryPicker category={category} setCategory={setCategory} />
                        </ListItem>
                    </List>
                    
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" onClick={send_reply}>Create Event!</Button>
                </CardActions>
      
            </Card>
            
        </MainLoggedInLayout>
    )
}