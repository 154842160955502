import { CircularProgress } from "@mui/material"

export const Loading = () => {
    return (
        <div style={{justifyContent:'center', alignItems:'center', display:'flex', height:'100vh'}}>
            <CircularProgress />
        </div>
    )
}

export const header_radio_set = [
    "community-1.jpg",
    "community-2.jpg",
    "community-3.jpg",
    "community-4.jpg",
    "community-5.jpg",
    "community-6.jpg",
    "community-7.jpg",
    "community-8.jpg"
]