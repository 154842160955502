import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Menu } from '@mui/icons-material';
import { MainMenu } from './MainMenu';
import { Box } from '@mui/material';
import { UserContext } from '../../context/UserContext';

export default function MainSidebar() {
  const [open, setOpen] = React.useState(false);

  const menu_style = {backgroundColor:'white', width:'100%', justifyContent:'center', display:'flex', position: 'sticky', top: '0px', zIndex:1}
  const userDetails = React.useContext(UserContext)
  return (
    userDetails?.token &&
    <div>
      <div style={menu_style} >
        <Button onClick={() => setOpen(!open)} startIcon={<Menu />}>Menu</Button>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)} style={{position:'sticky'}}>
        <Box sx={{ width: 250 }} role="presentation" onClick={() => setOpen(!open)}>
          {<MainMenu />}
        </Box>
      </Drawer>
    </div>
  );
}