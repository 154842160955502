import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';

export const PostImages = ({imgs}) => {
  let placeholder_img = 'https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/logo.svg'

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [index, setActiveStep] = useState(0);
    
      // Function to go to the next picture
    const goToNextPicture = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const goToPrevPicture = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let img = imgs[index]
  
    return (
      <>
      <div style={window.innerWidth < width ? {width: (window.innerWidth-40), margin:'auto'} : {width, height, margin:'auto'}}>
        {imgs.length > index+1 && <IconButton style={{top:"40%", float:'right'}} onClick={goToNextPicture}><ArrowForwardIos /></IconButton>}
        {index > 0 && <IconButton style={{top:"40%"}} onClick={goToPrevPicture}><ArrowBackIos /></IconButton>}
        {img?.url.includes("mp4") ?
        <div style={{placeContent:'center', display:'flex'}}>
            <video controls style={{height:'150px'}}>
            <source src={img?.url} type="video/mp4" />
            </video>
        </div>
        :
        <img
            srcSet={img?.url}
            src={img?.url}
            alt="post"
            style={{width:"100%", height:"100%"}}
            onLoad={e => {
              setWidth(img.meta.small.width)
              setHeight(img.meta.small.height)
                if(e.target.height < 10) e.target.src = placeholder_img
            }}
            onError={(e) => {
                e.target.style.textAlign = 'center';
                e.target.style.background = 'url('+placeholder_img+')';
                e.target.style.backgroundSize = 'contain';
                e.target.style.backgroundRepeat = 'no-repeat';
                if(e.currentTarget.src !== placeholder_img) 
                    e.target.src = placeholder_img
                }}
        />
        }
            
      </div>
      </>
    )
}