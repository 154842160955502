import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { editCommunity, getCommunity } from "../../controllers/community/community"
import { useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { Button, Card, CardActions, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Grid, List, ListItem, Radio, RadioGroup, TextField } from "@mui/material"
import { PostMedia } from "../../components/post/card/PostMedia"
import { useNavigate, useParams } from "react-router"
import { CategoryPicker } from "../../components/category.picker"
import { header_radio_set } from "../../util/util"

export const EditCommunity = () => {
    const userDetails = useContext(UserContext)
    const [header_img, setHeaderImg] = useState('')
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const [chooseHeader, setChooseHeader] = useState(true)
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')
    let navigate = useNavigate()
    const { id } = useParams()
    let token = userDetails?.token
    const { data:community, refetch } = useQuery({ queryKey: ['getSingleCommunity'], queryFn: () => getCommunity(token, id) })
    useEffect(() => {
        refetch()
    })

    let single_community = useMemo(() => community?.community ?? {}, [community?.community]) 
    
    useEffect(() => {
        single_community?.title && setTitle(single_community?.title)
        single_community?.category && setCategory(single_community?.category)
        setHeaderImg(single_community?.header_img ?? 'https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/community-1.jpg')
    }, [single_community])
    
    let field_set = [
        {id:"title", value:title, setValue:setTitle, label:"Title"},
    ]

    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: editCommunity,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editCommunity'] })
        navigate("/community/show/"+single_community?._id)
        },
    })
    
    const sendEditedCommunity = () => { 
        mutation.mutate({
          id:single_community?._id,
          body:{title, header_img, leader:single_community?.leader, category},
          token:userDetails?.token
        })
    }

    return (
        <MainLoggedInLayout>
            {single_community?.leader && single_community?.leader?.filter(item => item.id.includes(userDetails?.id)).length !== 0 ?
            <Card>
                <CardHeader
                    title="Edit a community!"
                    subheader={<Button variant="contained" color="error" onClick={() => navigate("/community/delete/"+community?.community?._id)}>Delete</Button>}
                />
                <CardContent>
            
                    <List>
                    
                        {field_set.map((field, i) =>
                            <ListItem key={i}>
                                <TextField fullWidth id={field.id} value={field?.value} onChange={(e) => field.setValue(e.target.value)} label={field.label} variant="outlined" />
                            </ListItem>
                        )}

                        <ListItem>
                            <CategoryPicker category={category} setCategory={setCategory} />
                        </ListItem>

                        <ListItem>
                            {chooseHeader ?
                            <Button variant="contained" onClick={() => setChooseHeader(false)}>Upload Community Image</Button>
                            :
                            <Button variant="contained" onClick={() => setChooseHeader(true)}>Choose Community Image</Button>
                            }
                        </ListItem>

                        <ListItem>- OR -</ListItem>

                        {chooseHeader ?
                        <ListItem>

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Choose Community Image</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={header_img}
                                    onChange={(e) => {
                                        setHeaderImg(e.target.value)}}
                                >
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {header_radio_set.map((radio, index) =>
                                        <Grid item xs={4} sm={4} md={6} key={index}>
                                            <FormControlLabel value={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} control={<Radio />} label={<img alt={"community pics"} src={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} width={150} height={70} />} />
                                        </Grid>
                                        )}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>

                        </ListItem>
                        :
                        <ListItem>
                        <FormLabel id="demo-row-radio-buttons-group-label">Upload Community Image</FormLabel>

                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={userDetails?.token} />
                        </ListItem>
                        }
                    </List>
                    
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" onClick={sendEditedCommunity}>Update Community!</Button>
                </CardActions>
      
            </Card>
            :
            "You can not access this page"
            }
            
        </MainLoggedInLayout>
    )
}