import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useNavigate, useParams } from "react-router"
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { deleteCommunity, getCommunity } from "../../controllers/community/community"
import { useContext, useEffect } from "react"
import { UserContext } from "../../context/UserContext"

export const DeleteCommunity = () => {
    const { id } = useParams()
    let navigate = useNavigate()    
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const { data:community, refetch } = useQuery({ queryKey: ['getSingleCommunity'], queryFn: () => getCommunity(token, id) })

    useEffect(() => {
        refetch()
    })
    
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: deleteCommunity,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['deleteCommunity'] })
        navigate('/community/list')
        },
    })

    const deleteComm = () => {
        mutation.mutate({
          id,
          token
        })
    }
    return (
        <MainLoggedInLayout>
            {community?.community?.leader && community?.community?.leader?.filter(item => item.id.includes(userDetails?.id)).length !== 0 ?
            <Card>
                <CardHeader
                title="Are you sure you want to delete this community?"
                 />
                <CardContent>
                    You can not recover this community once deleted.
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" color="error" onClick={deleteComm}>Yes, Delete</Button>
                </CardActions>
            </Card>
            :
            "You can not access this page"
            }
        </MainLoggedInLayout>
    )
}