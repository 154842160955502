let api = process.env.REACT_APP_MKGF_MASTODON_API+'/event'

export const getAllEvents = async (token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/all" , { headers });
    const data = await response.json();
    return data
}

export const searchAllEvents = async ({token, title, page_number, category, limit, lat, lng, miles}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/search?title="+(title ?? '')+"&page_number="+page_number+"&limit="+limit+"&category="+(category ?? '')+"&miles="+miles+"&lat="+(lat ?? '')+"&lon="+lng, { headers });
    const data = await response.json();
    return data
}

export const getEvent = async (token, id) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { headers });
    const data = await response.json();
    return data
}

export const createEvent = async ({token, category, title, desc, start_date_time, end_date_time, location, header_img, author, status}) => {
    let body = {title, category, desc, start_date_time, end_date_time, location, header_img, author, status}
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/" , { method:"POST", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const editEvent = async ({token, id, body}) => {
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/"+id , { method:"PUT", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const deleteEvent = async ({token, id, status_id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id+"/"+status_id , { method:"DELETE", headers });
    const data = await response.json();
    return data
}