import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useNavigate, useParams } from "react-router"
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { deleteEvent, getEvent } from "../../controllers/event/event"
import { useContext, useEffect } from "react"
import { UserContext } from "../../context/UserContext"
import { deleteSinglePost } from "../../controllers/posts/posts"

export const DeleteEvent = () => {
    const { id } = useParams()
    let navigate = useNavigate()    
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const { data:event, refetch } = useQuery({ queryKey: ['getSingleEvent'], queryFn: () => getEvent(token, id) })
    let single_event = event?.event ?? {}
    useEffect(() => {
        refetch()
    })
    
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: deleteEvent,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['deleteEvent'] })
        navigate('/event/list')
        },
    })
    const mutationMastodonDelete = useMutation({
        mutationFn: deleteSinglePost,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['deleteSinglePost'] })
        },
    })

    const deleteEvnt = () => {
        // delete mastodon status item
        mutationMastodonDelete.mutate({
            id:single_event?.status,
            token,
        })

        // delete mongodb event item
        mutation.mutate({
          id,
          token,
          status_id: single_event?.status
        })
    }
    return (
        <MainLoggedInLayout>
            {single_event?.author?.id === userDetails?.id ?
            <Card>
                <CardHeader
                title="Are you sure you want to delete this event?"
                 />
                <CardContent>
                    You can not recover this event once deleted.
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" color="error" onClick={deleteEvnt}>Yes, Delete</Button>
                </CardActions>
            </Card>
            :
            "You can not access this page"
            }
        </MainLoggedInLayout>
    )
}