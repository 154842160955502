import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddBox, CalendarMonth, Diversity1, Explore, Group, ListAlt, Logout, Notifications, Send, Settings } from '@mui/icons-material';
import { getLogout } from '../../controllers/accounts/auth';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

export const MainMenu = () => {
    let navigate = useNavigate()
    const userDetails = useContext(UserContext)
    return ( 
    userDetails?.id &&
    <Box sx={{ width: 250 }}>
      <List>
        {[{label:'Members', icon:<Group />, url:"/members"}, {label:'Message', icon:<Send />, url:"/messages"}, {label:'Explore', icon:<Explore />, url:"/explore"}, {label:'Communites', icon:<Diversity1 />, url:"/community/list"}, {label:'Forum', icon:<ListAlt />, url:"/forum/list"}, {label:'Events', icon:<CalendarMonth />, url:"/event/list"}, {label:'Upload', icon:<AddBox />, url:"/post/create"}].map((text, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => navigate(text.url)}>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[{label:'Account', icon:<Avatar alt={userDetails?.username} src={userDetails?.avatar} />, url:"/profile/"+userDetails?.id}, {label:'Notifications', icon:<Notifications />, url:"/notifications"}, {label:'Settings', icon:<Settings />, url:"/settings"}].map((text, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => navigate(text.url)}>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
            <ListItemButton onClick={getLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
        </ListItem>
      </List>
    </Box>
    )
}