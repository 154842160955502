import { QueryClient, useMutation } from "@tanstack/react-query"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { createCommunity } from "../../controllers/community/community"
import { useContext, useEffect, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { Button, Card, CardActions, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Grid, List, ListItem, Radio, RadioGroup, TextField } from "@mui/material"
import { PostMedia } from "../../components/post/card/PostMedia"
import { useNavigate } from "react-router"
import { CategoryPicker } from "../../components/category.picker"
import { header_radio_set } from "../../util/util"

export const CreateCommunity = () => {
    const userDetails = useContext(UserContext)
    const [header_img, setHeaderImg] = useState('')
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const [chooseHeader, setChooseHeader] = useState(true)
    const [category, setCategory] = useState('')
    let navigate = useNavigate()

    let field_set = [
        {id:"title", value:"", setValue:() => {}, label:"Title"},
    ]

    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: createCommunity,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createCommunity'] })
        navigate("/community/show/"+data.community._id)
        },
    })

    useEffect(() => {
      setHeaderImg(imageUrls[imageUrls.length - 1])
    }, [imageUrls])

    useEffect(() => {
      setHeaderImg('https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/community-1.jpg')
    }, [])
    
    const sendCreateCommunity = () => {
        let title = document.getElementById("title").value
        
        mutation.mutate({
          title,
          header_img,
          leader:{id:userDetails?.id, avatar:userDetails?.avatar, username:userDetails?.acct},
          token:userDetails?.token,
          category
        })
    }

    return (
        <MainLoggedInLayout>
            <Card>
                <CardHeader
                    title="Create a community!"
                    subheader={new Date().toDateString()}
                />
                <CardContent>
            
                    <List>
                    
                        {field_set.map((field, i) =>
                            <ListItem key={i}>
                                <TextField fullWidth id={field.id} onChange={(e) => field.setValue(e.target.value)} label={field.label} variant="outlined" />
                            </ListItem>
                        )}

                        <ListItem>
                            <CategoryPicker category={category} setCategory={setCategory} />
                        </ListItem>

                        <ListItem>
                            {chooseHeader ?
                            <Button variant="contained" onClick={() => setChooseHeader(false)}>Upload Community Image</Button>
                            :
                            <Button variant="contained" onClick={() => setChooseHeader(true)}>Choose Community Image</Button>
                            }
                        </ListItem>

                        <ListItem>- OR -</ListItem>

                        {chooseHeader ?
                        <ListItem>

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Choose Community Image</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={header_img}
                                    onChange={(e) => {
                                        setHeaderImg(e.target.value)}}
                                >
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {header_radio_set.map((radio, index) =>
                                        <Grid item xs={4} sm={3} md={6} key={index}>
                                            <FormControlLabel value={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} control={<Radio />} label={<img alt={"community pics"} src={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} width={150} height={70} />} />
                                        </Grid>
                                        )}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>

                        </ListItem>
                        :
                        <ListItem>
                        <FormLabel id="demo-row-radio-buttons-group-label">Upload Community Image</FormLabel>

                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={userDetails?.token} />
                        </ListItem>
                        }
                    </List>
                    
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" onClick={sendCreateCommunity}>Create Community!</Button>
                </CardActions>
      
            </Card>
            
        </MainLoggedInLayout>
    )
}