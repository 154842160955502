import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { AddBox, Explore, ViewDay, WavingHand } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Avatar } from '@mui/material';
import { CreatePost } from '../post/CreatePost';

export default function MainBottomNavigation() {
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false)
  const [status, setStatus] = React.useState("")
  let navigate = useNavigate()
  const userDetails = React.useContext(UserContext)
  return (
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        
      >
        <BottomNavigationAction onClick={() => {
          setValue(0)
          setOpen(true)
          }} label="Upload" icon={<AddBox />} />
          <CreatePost token={userDetails?.token} open={open} setOpen={setOpen} status={status} setStatus={setStatus} buttonTitle={"Upload Status"} visibility={'public'} />
        <BottomNavigationAction onClick={() => {
          setValue(1)
          navigate("/explore")
          }} label="Explore" icon={<Explore />} />
        <BottomNavigationAction onClick={() => {
          setValue(2)
          navigate("/meet")
          }} label="Meet" icon={<WavingHand />} />
        <BottomNavigationAction onClick={() => {
          setValue(3)
          navigate("/feed")
          }} label="Feed" icon={<ViewDay />} />
        <BottomNavigationAction onClick={() => {
          setValue(4)
          navigate("/profile/"+userDetails?.id)
          }} label="Account" icon={<Avatar alt={userDetails?.username} src={userDetails?.avatar} />} />
      </BottomNavigation>
  );
}