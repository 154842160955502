import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { Button, Card, CardActions, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Grid, List, ListItem, Radio, RadioGroup, TextField } from "@mui/material"
import { PostMedia } from "../../components/post/card/PostMedia"
import { useNavigate, useParams } from "react-router"
import { editEvent, getEvent } from "../../controllers/event/event"
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { CategoryPicker } from "../../components/category.picker"
import Autocomplete from "react-google-autocomplete";
import { getSinglePost } from "../../controllers/posts/posts"
import { editComment } from "../../controllers/posts/comments"
import { header_radio_set } from "../../util/util"

export const EditEvent = () => {
    let YOUR_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_YOUR_GOOGLE_MAPS_API_KEY
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const [header_img, setHeaderImg] = useState('https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/community-1.jpg')
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const [chooseHeader, setChooseHeader] = useState(true)
    const [startdate, setStartDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const [category, setCategory] = useState('')
    const [status, setStatus] = useState('')
    const [location, setLocation] = useState('')
    const [title, setTitle] = useState('')
    let navigate = useNavigate()
    const { id } = useParams()
    const { data:event, refetch } = useQuery({ queryKey: ['getSingleEvent'], queryFn: () => getEvent(token, id) })
    const { data:content, refetch:refetchPost } = useQuery({ queryKey: ['getSinglePost'], queryFn: () => getSinglePost(single_event.status, token) })

    useEffect(() => {
        refetch()
        refetchPost()
    })

    let single_event = useMemo(() => event?.event ?? {} ,[event?.event])

    const queryClient = new QueryClient()

    const mutationComment = useMutation({
      mutationFn: editComment,
      onSuccess: (data) => {
        // Invalidate and refetch
        console.log(data)
        queryClient.invalidateQueries({ queryKey: ['editComment'] })
        navigate("/event/show/"+single_event?._id)
      },
    })
  
    const send_edit = () => {
        mutationComment.mutate({
        id:single_event?.status,
        body:{
            status,
            media_ids
        },
        token
      })
    }

    const mutation = useMutation({
        mutationFn: editEvent,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editEvent'] })
        send_edit()
        },
    })

    useEffect(() => {
        setTitle(single_event?.title ?? '')
        setStartDate(new Date(single_event?.start_date_time ?? ''))
        setEndDate(new Date(single_event?.end_date_time ?? ''))
        setLocation(single_event?.location ?? '')
        setCategory(single_event?.category ?? '')
        setHeaderImg(single_event?.header_img ?? 'https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/community-1.jpg')
    }, [single_event])

    useEffect(() => {
      setStatus(content?.content ?? '')
      let img_urls = []
      content?.media_attachments?.filter(item => img_urls.push(item.url))
      setImageUrls(img_urls)
    }, [content])
    
    const sendEditEvent = () => {
        
        mutation.mutate({
          title,
          id:single_event?._id,
          body: {
            desc:status,
            start_date_time:startdate,
            end_date_time:enddate,
            location,
            category,
            status:single_event?.status,
            header_img,
            author:{id:userDetails?.id, avatar:userDetails?.avatar, username:userDetails?.acct}
          },
          token:userDetails?.token
        })
    }

    return (
        <MainLoggedInLayout>
            <Card>
                <CardHeader
                    title="Create an event!"
                    subheader={<Button variant="contained" color="error" onClick={() => navigate("/event/delete/"+single_event?._id)}>Delete</Button>}
                />
                <CardContent>
            
                    <List>
                    
                        <ListItem>
                            <TextField fullWidth value={title} onChange={(e) => setTitle(e.target.value)} label={"Title"} variant="outlined" />
                        </ListItem>

                        <ListItem>
                            {chooseHeader ?
                            <Button variant="contained" onClick={() => setChooseHeader(false)}>Upload Event Image</Button>
                            :
                            <Button variant="contained" onClick={() => setChooseHeader(true)}>Choose Event Image</Button>
                            }
                        </ListItem>

                        <ListItem>- OR -</ListItem>

                        {chooseHeader ?
                        <ListItem>

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Choose Event Image</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={header_img}
                                    onChange={(e) => {
                                        setHeaderImg(e.target.value)}}
                                >
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {header_radio_set.map((radio, index) =>
                                        <Grid item xs={4} sm={4} md={6} key={index}>
                                            <FormControlLabel value={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} control={<Radio />} label={<img alt={"community pics"} src={"https://storage.googleapis.com/events.makegayfriends.com/event_images/uploads/"+radio} width={150} height={70} />} />
                                        </Grid>
                                        )}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>

                        </ListItem>
                        :
                        <ListItem>
                        <FormLabel id="demo-row-radio-buttons-group-label">Upload Event Image</FormLabel>

                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={userDetails?.token} />
                        </ListItem>
                        }

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={4} sm={4} md={6}>
                                <ListItem>
                                    Start Date And Time
                                </ListItem>

                                <ListItem>
                                    <Datetime value={startdate} onChange={(value) => setStartDate(value._d)} />
                                </ListItem>
                            </Grid>

                            <Grid item xs={4} sm={4} md={6}>
                                <ListItem>
                                    End Date And Time
                                </ListItem>

                                <ListItem>
                                    <Datetime value={enddate} onChange={(value) => setEndDate(value._d)} />
                                </ListItem>
                            </Grid>
                        </Grid>

                        <ListItem>                            
                            <Autocomplete
                                className="form-control"
                                apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                onPlaceSelected={(place) => {
                                    setLocation(place)
                                }}
                                defaultValue={location?.formatted_address}
                            />
                        </ListItem>

                        <div style={{marginLeft:20}} dangerouslySetInnerHTML={{__html: status }} />
                        <div style={{marginLeft:20}}>
                            <TextField placeholder="Update Description" onChange={(e) => setStatus(e.target.value)} fullWidth multiline id="outlined-basic" label='Update Description' variant="outlined" />
                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={token} />
                        </div>

                        <ListItem>
                            <CategoryPicker category={category} setCategory={setCategory} />
                        </ListItem>
                    </List>
                    
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" onClick={sendEditEvent}>Update Event!</Button>
                </CardActions>
      
            </Card>
            
        </MainLoggedInLayout>
    )
}