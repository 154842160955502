import { Button } from "@mui/material"
import { CreatePost } from "../post/CreatePost"
import { useEffect, useState } from "react"
import { followManager, getFollowingList } from "../../controllers/accounts/accounts"
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"

export const FollowAccountManager = ({auth_id, id, token}) => {
    const { data:following } = useQuery({ queryKey: ['getFollowingList'], queryFn: () => getFollowingList(id=auth_id, token) })
    const [followingList, setFList] = useState([])
    const [isFollowing, setIsFollowing] = useState(false)
    
    useEffect(() => {
        if(following?.length > 0){
            following?.filter(item => setFList(followingList => [...followingList, item.id]))
        }
    }, [following])

    useEffect(() => {
        setIsFollowing(followingList?.includes(id))
    }, [followingList, id])
    

    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: followManager,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['follow_manager'] })
        setIsFollowing(!isFollowing)
        },
    })

    const send_follow = () => {
        mutation.mutate({
            token, 
            follow: isFollowing ? false : true, 
            id
        })
    }
    
    return (
        <Button onClick={send_follow} variant="contained" color={isFollowing ? 'error' : 'primary'} style={{marginRight:"5px", marginBottom:"5px"}}>{isFollowing ? 'UnFollow' : 'Follow'}</Button>
    )
}

export const MessageUser = ({user, token}) => {
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState("")

    const create_message = () => {
        setOpen(true)
        setStatus("@"+user)
    }
    return (
        <>
        <Button onClick={create_message} variant="contained" style={{marginBottom:"5px"}}>Message</Button>
        <CreatePost token={token} open={open} setOpen={setOpen} status={status} setStatus={setStatus} buttonTitle={"Send Message"} visibility={'direct'} />
        </>
    )
}