import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { CheckCircle, DoNotDisturb } from '@mui/icons-material';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { editCommunity } from '../../controllers/community/community';
import { UserContext } from '../../context/UserContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function MemberRequests({members, community_id, requests}) {
    const userDetails = React.useContext(UserContext)
    const [openRequest, setOpenRequest] = React.useState(false);

    const handleClickOpen = () => {
      setOpenRequest(true);
    };
    const handleClose = () => {
      setOpenRequest(false);
    };

    const queryClient = new QueryClient()
    const mutationEdit = useMutation({
        mutationFn: editCommunity,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editCommunity'] })
        setOpenRequest(false)
        },
    })


    const handleRequest = (member_id, type) => {
        const createBody = () => {
            if(type === "add"){
                return {
                    member:[...members, {id:userDetails?.id, avatar:userDetails?.avatar, username:userDetails?.acct}], 
                    member_request:requests.filter(item => item.id !== member_id )
                }
            } else {
                return {member_request:requests.filter(item => item.id !== member_id )}
            }
        }
        mutationEdit.mutate({
            id:community_id, 
            body:createBody(),
            token: userDetails?.token
        })
    }

  return (
    <React.Fragment>
      {requests?.length > 0 &&
        <Button variant="contained" onClick={handleClickOpen}>
            Member Requests
        </Button>
      }
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openRequest}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Request to join
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {requests?.map((member, i) => {
                    return (
                    <ListItem
                        key={i}
                        disablePadding
                    >
                        <ListItemButton>
                        <ListItemAvatar>
                            <Avatar
                            alt={`member`}
                            src={member.avatar}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={member.username} />
                        
                        <div>
                            <Button onClick={() => handleRequest(member.id, 'remove')} color='error'><DoNotDisturb /></Button>
                            
                            <Button onClick={() => handleRequest(member.id, 'add')}><CheckCircle /></Button>
                        </div>
                        </ListItemButton>
                    </ListItem>
                    );
                })}
            </List>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}