import { Dangerous, ElectricBolt, FastRewind, Settings, WavingHand } from "@mui/icons-material"
import { Button, Card, CardActionArea, CardActions, CardContent, Dialog, DialogContent, DialogContentText, ListItem, Typography } from "@mui/material"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { createComment } from "../../controllers/posts/comments"
import { UserContext } from "../../context/UserContext"
import { useContext, useState } from "react"
import { deleteMatch, editMatch } from "../../controllers/hey_match/match"

export const SwipeCards = ({ 
    setMatches, 
    setAccount, 
    filterAccounts, 
    local, 
    setLocal, 
    accts_viewed, 
    count, 
    offset, 
    setOffset, 
    swipeIdx, 
    setSwipeIdx, 
    account, 
    errImg, 
    setErrImg
}) => {
    const userDetails = useContext(UserContext)
    const [openDialog, setOpenDialog] = useState(false)
    const [acct_count, setAcctCount] = useState(0)

    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: createComment,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createComment'] })
        },
    })

    const match_mutation = useMutation({
        mutationFn: editMatch,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editMatch'] })
        },
    })

    const match_delete_mutation = useMutation({
        mutationFn: deleteMatch,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editMatch'] })
        setOpenDialog(false)
        setMatches({})
        },
    })

    const resetMatches = () => {
        match_delete_mutation.mutate({
        id: userDetails?.id,
        token: userDetails?.token
        })

    }

    const send_reply = (status) => {
        mutation.mutate({
        body:{
            status,
            visibility:"direct"
        },
        token: userDetails?.token
        })
    }

    const udpate_match = (x) => {
        if(x){
            setAcctCount(acct_count-1)
        } else {
            setAcctCount(acct_count+1)
        }
        let filteredView = accts_viewed?.filter(item => item !== accts_viewed[accts_viewed.length-1]) 
        match_mutation.mutate({
            id:userDetails?.id,
            viewed: x ? filteredView : [...accts_viewed, account.id],
            token: userDetails?.token
        })
    }

    const handleNext = (type) => {
        if(!userDetails?.id){
            window.location.href = 'https://makegayfriends.com/oauth/authorize?client_id=xXcvjaPVNZr3B91U-93Gdl6RUHqYO0XzCTlp_UhUQmk&scope=read+write&redirect_uri=https://beta.makegayfriends.com&response_type=code'
        }
        if(type === 'rewind'){
            setSwipeIdx(swipeIdx-1)
            setAccount(filterAccounts[swipeIdx-1])
            udpate_match('remove')
            return
        }

        //pagniation
        if(swipeIdx >= count){
            setSwipeIdx(0)
            setAccount(filterAccounts[0])
            setOffset(offset+1)
        } else {
            setSwipeIdx(swipeIdx+1)
            setAccount(filterAccounts[swipeIdx+1])
        }
        
        //update match API
        udpate_match(null)

        //send direct message
        switch (type) {
            case 'hey':
                send_reply("@"+account?.acct+" hey");
                break;
            case 'skip':
                break;
            case 'super':
                send_reply("@"+account?.acct+" SUPER hey!");
                break;
            default:
              console.log(`Sorry, we are out of ${type}.`);
          }
          
    }

    if(!account){
        return (
            "Loading..."
        )
    }
    
    return (
        <Card>
            <CardActionArea>
                <img
                    src={account.avatar}
                    alt={account.acct}
                    style={{width:'100%'}}
                    onError={() => setErrImg(errImg => [...errImg, account.id])}
                />
                <CardContent>
                <Typography variant="p">
                    @{account.acct}
                </Typography>

                <div
                    dangerouslySetInnerHTML={{__html: account.note}}
                />
                </CardContent>
            </CardActionArea>
            <CardActions style={{justifyContent:'center', display:'flex'}}>
                {acct_count > 0 &&
                <Button size="small" style={{color:'#b1b148'}} onClick={() => handleNext('rewind')}>
                    <FastRewind /> 
                </Button>}
                <Button size="small" color="error" onClick={() => handleNext('skip')}>
                    <Dangerous /> Skip
                </Button>
                <Button size="small" color="primary" onClick={() => handleNext('super')}>
                    <ElectricBolt /> Super
                </Button>
                <Button size="small" color="success" onClick={() => handleNext('hey')}>
                    <WavingHand /> Hey
                </Button>
                {userDetails?.id &&
                <Button size="small" style={{color:'gray'}} onClick={() => setOpenDialog(true)}>
                    <Settings /> 
                </Button>}
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {local ?
                    <ListItem style={{display:'block', textAlign:'center'}}>
                        You are currently only viewing members on MakeGayFriends.com's server. 
                        You can view members connected to our server that are hosted on other servers on Mastodon.
                        <Button fullWidth color="warning" onClick={() => {
                            setLocal(false)
                            setOpenDialog(false)
                            }}>View other servers</Button>
                        
                    </ListItem>
                    :
                    <ListItem style={{display:'block', textAlign:'center'}}>
                        You are currently viewing members on all servers on the Mastodon network connected to MakeGayFriends.com.
                        You can view only MakeGayFriends.com's members.
                        <Button fullWidth color="warning" onClick={() => {
                            setLocal(true)
                            setOpenDialog(false)
                            }}>View MakeGayFriends's server</Button>
                        
                    </ListItem>
                    }
                    <ListItem style={{display:'block', textAlign:'center'}}>
                        You can reset your views. This will delete your view history.
                        <Button fullWidth onClick={resetMatches}>Reset Results</Button>

                    </ListItem>
                </DialogContentText>
                </DialogContent>
            </Dialog>
            </CardActions>
        </Card>
    )
}