import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { MainLoggedInLayout } from '../../components/layouts/main.loggedIn.layout';
import { Avatar, Button, InputLabel, MenuItem, Select, TextField, Typography, styled } from '@mui/material';
import { UserContext } from '../../context/UserContext';
import { getSingleAccount, updateAvatar, updateSingleAccount } from "../../controllers/accounts/accounts";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { CloudUploadOutlined } from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export function AccountEdit() {
    const [body, setBody] = React.useState('');
    const [newAvatar, setNewAvatar] = React.useState('')
    const userDetails = React.useContext(UserContext)
    let token = userDetails?.token ?? ''
    let id = userDetails?.id
    const { data:account, refetch } = useQuery({ queryKey: ['getSingleAccount'], queryFn: () => getSingleAccount(id, token) })
    const [visibility, setVisibility] = React.useState(account?.account?.discoverable ?? '')
    let labelStyle = {marginLeft:'5%'}

    React.useEffect(() => {
      refetch()
    })

    let fields_key =[
        {label:"Username", value:account?.account?.username, id:"username"},
        {label:"Display Name", value:account?.account?.display_name, id:"display_name"},
    ]

    const handleChange = () => {
        let localValues = {}
        document.getElementById("username").value && Object.assign(localValues, {username:document.getElementById("username").value})
        document.getElementById("display_name").value && Object.assign(localValues, {display_name:document.getElementById("display_name").value})
        document.getElementById("bio").value && Object.assign(localValues, {note:document.getElementById("bio").value})
        document.getElementById("visibility").value && Object.assign(localValues, {visibility:visibility})
        setBody(localValues)
    };

    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: updateSingleAccount,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['updateSingleAccount'] })
        },
    })

    const updateAccount = () => {
        mutation.mutate({
          body,
          token
        })
    }

    const mutation2 = useMutation({
        mutationFn: updateAvatar,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['updateAvatar'] })
        setNewAvatar(data.avatar)
        },
    })

    const updateAccountAvatar = (image) => {
        mutation2.mutate({
            image, 
            token
        })
    }
  return (
    <MainLoggedInLayout>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        
        <div style={labelStyle}>
            <Typography>
                Edit Profile
            </Typography>

            <Avatar style={{marginBottom:10, width:75, height:75}} alt={newAvatar ?? account?.account?.username} src={account?.account?.avatar} />

            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadOutlined />}
                sx={{marginBottom:"10px"}}
            >
                Update Avatar
                <VisuallyHiddenInput type="file" accept="image/*" onChange={(value) => updateAccountAvatar(value.target.files[0])} />
            </Button>
        </div>

        {fields_key.map((value, i) => (
            <React.Fragment key={i}>
                <InputLabel style={labelStyle} id="demo-simple-select-label">{value.label}</InputLabel>
                <ListItem
                    key={id}
                    disableGutters
                >
                    <ListItemText primary={<TextField onChange={handleChange} style={{width:'90%', marginLeft:'5%'}} id={value.id} placeholder={value.value} variant="outlined" />} />
                </ListItem>
            </React.Fragment>
        ))}
        <InputLabel style={labelStyle} id="demo-simple-select-label">Bio</InputLabel>
        <ListItemText primary={<TextField onChange={handleChange} style={{width:'90%', marginLeft:'5%'}} id="bio" multiline rows={4} placeholder={account?.account?.note?.replaceAll(/(<([^>]+)>)/ig, '').replaceAll('&#39;', "'")} variant="outlined" />} />
        <ListItemText primary={
            <div style={{width:'90%', marginLeft:'5%'}}>
                <InputLabel id="demo-simple-select-label">Profile Visibility</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="visibility"
                    value={visibility}
                    label="Age"
                    onChange={(e) => {
                        setVisibility(e.target.value)
                        handleChange()
                    }}
                    style={{width:'100%'}}
                >
                    <MenuItem value={true}>Public</MenuItem>
                    <MenuItem value={false}>Private</MenuItem>
                </Select>
            </div>
        } />

        <Button onClick={updateAccount} disabled={Object.keys(body).length > 0 ? false : true} variant='contained' style={{display:'block', margin:'auto'}}>Update Account</Button>

        </List>
        <div style={{marginTop:100}}>
            <Button color='error' variant='contained' style={{display:'block', margin:'auto'}}>Delete Account</Button>
        </div>
        
    </MainLoggedInLayout>
  );
}