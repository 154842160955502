import { useContext, useState } from "react"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { CreatePost } from "../../components/post/CreatePost"
import { UserContext } from "../../context/UserContext"
import { Alert, Button } from "@mui/material"
import { Add, InsertEmoticon } from "@mui/icons-material"

export const PostCreate = () => {
    const userDetails = useContext(UserContext)
    const [open, setOpen] = useState(true)
    const [status, setStatus] = useState("")
    return (
        <MainLoggedInLayout>
            <div style={{height:'70vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <div style={{textAlign:'center'}}>
                    <Alert icon={<InsertEmoticon fontSize="inherit" />} severity="info">
                        Thanks for participating in our community by adding posts.
                    </Alert>
                    <Button style={{marginTop: 5}} variant="contained" color="primary" onClick={() => setOpen(true)} startIcon={<Add />}>
                        Add to the community!
                    </Button>
                </div>
            </div>
            <CreatePost token={userDetails?.token} open={open} setOpen={setOpen} status={status} setStatus={setStatus} buttonTitle={"Upload Status"} visibility={'public'} />
        </MainLoggedInLayout>
    )
}