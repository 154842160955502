import { Grid, Stack } from "@mui/material"
import MainBottomNavigation from "../../components//navs/Bottom.Navigation.jsx"
import MainSidebar from "../../components/navs/Sidebar.jsx"
import { MainMenu } from "../../components/navs/MainMenu.jsx"
import useWindowDimensions from "../../util/isMobile.js"
import { Login } from "../account/login.jsx"
import { useContext } from "react"
import { UserContext } from "../../context/UserContext.js"

export const MainLoggedInLayout = ({children}) => {
    const { width } = useWindowDimensions()
    
    const middle_col = {width:'100%', display:'grid', overflow:'auto', overflowStyle: 'none', scrollbarWidth: 'none', height:'92vh'}
    
    const gay_gifs = [
        'I_noticed_your_gay',
        'alt_gay_flag',
        'gay_bears',
        'gay_dim_flag',
        'gay_juice',
        'gay_like',
        'prenium_snack',
        'rainbow1',
        'waving_flag',
        'happy_pride'
    ]
    
    const chosen_gif = gay_gifs[Math.floor(Math.random() * gay_gifs.length)]
    
    const userDetails = useContext(UserContext)

    const user = userDetails?.id ? true : false
    
    return (
        <>
            {width < 900 &&
                <MainSidebar />
            }
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {width > 900 &&
                <Grid item xs={3}>
                    
                    <MainMenu />
                </Grid>
                }
                <Grid item xs={width > 900 ? 6 : 12}>
                    <div style={middle_col}>
                        <Stack spacing={2} >
                            {children}
                            <div style={{margin:10}}></div>
                        </Stack>
                    </div>
                    {width < 900 && userDetails?.token && <div style={{height:'4vh'}}></div>}
                </Grid>
                {width > 900 &&
                <Grid item xs={3}>
                    <img width={"100%"} src={'https://storage.googleapis.com/events.makegayfriends.com/gifs/'+chosen_gif+".gif"} alt={chosen_gif} />
                </Grid>
                }
            </Grid>
            <div style={{position:'fixed', bottom:0, width:'100%'}}>
                {user ? <MainBottomNavigation /> : <Login />}
            </div>
        </>
    )
}