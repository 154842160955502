import { Check, Favorite, FavoriteBorder, Repeat, RepeatOneOnOutlined } from "@mui/icons-material"
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { deleteSinglePost, favouriteSinglePost, reblogSinglePost } from "../../../controllers/posts/posts"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { createReport } from "../../../controllers/report/report"

export const Likes = ({favourited, count, id, token}) => {
    const [liked, setLiked] = useState(favourited)
    const [like_count, setLikeCount] = useState(count)
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: favouriteSinglePost,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['favourite'] })
        },
    })

    const send_like = () => {
        mutation.mutate({
            id, 
            token, 
            favourite: liked ? false : true
        })
        setLiked(!liked)
        setLikeCount(liked ? like_count -1 : like_count +1)
    }

    useEffect(() => {
      setLiked(favourited)
      setLikeCount(count)
    }, [favourited, count])
    
    return (  
        <Button onClick={send_like} style={{minWidth:'fit-content'}} size="small">{like_count}{liked ? <Favorite /> : <FavoriteBorder />}</Button>
    )
}

export const Reblog = ({reblogged, count, id, token}) => {
    const [reblog, setReblogged] = useState(reblogged)
    const [reblog_count, setReblogCount] = useState(count)
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: reblogSinglePost,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['reblog'] })
        setReblogged(!reblog)
        setReblogCount(reblog ? reblog_count -1 : reblog_count +1)
        },
    })

    const send_reblog = () => {
        mutation.mutate({
            id, 
            token, 
            reblog: reblog ? false : true
        })
    }

    useEffect(() => {
        setReblogged(reblogged)
        setReblogCount(count)
    }, [reblogged, count])
    
    return (  
        <Button onClick={send_reblog}>{reblog_count} {reblog ? <RepeatOneOnOutlined /> : <Repeat />}</Button>
    )
}

export const CreateReport = ({account_id, status_ids, token}) => {
    const [report_sent, setReportSent] = useState(false)
    const [report_request, setReportRequest] = useState(false)
    const [category, setCategory] = useState('')
    const [commentText, setCommentText] = useState('')
    let body = {account_id, status_ids, category, comment:commentText}
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: createReport,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['create_report'] })
        setReportSent(true)
        },
    })

    const send_report = () => {
        mutation.mutate({
            body, 
            token
        })
    }
    
    return (  
        <>
        {report_sent ?
        
        <Alert icon={<Check fontSize="inherit" />} severity="success">
            Report Sent.
        </Alert>
        : report_request ?
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Age"
                onChange={e => setCategory(e.target.value)}
            >
                <MenuItem value={'spam'}>Spam</MenuItem>
                <MenuItem value={'legal'}>Legal</MenuItem>
                <MenuItem value={'other'}>Other</MenuItem>
            </Select>
            <TextField
                id="outlined-multiline-flexible"
                label="Comments"
                onChange={(e) => setCommentText(e.target.value)}
                multiline
                maxRows={4}
                style={{margin:5}}
            />
            <Button onClick={send_report} variant='contained' fullWidth color='warning'>
                Send Report
            </Button>
        </FormControl>
        :

        <Button onClick={() => setReportRequest(true)} variant='contained' fullWidth color='warning'>
            Report
        </Button>
        }
        </>
    )
}

export const DeletePost = ({id, token}) => {
    let navigate = useNavigate()
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: deleteSinglePost,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['deletePost'] })
        navigate("/explore")
        },
    })

    const delete_post = () => {
        mutation.mutate({
            id, 
            token
        })
    }
    
    return ( 
        <Button onClick={delete_post} variant='contained' fullWidth color='error'>
            Delete
        </Button> 
    )
}