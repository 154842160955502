import { useQuery } from "@tanstack/react-query"
import { PostList } from "./post/PostList"
import { useContext, useEffect, useState } from "react"
import { publicTimeline } from "../controllers/explore/explore"
import { UserContext } from "../context/UserContext";
import { Button, Chip, Divider, Grid } from "@mui/material";
import { searchAllForums } from "../controllers/forum/forum";
import { CommunityCard as ForumCard } from "../views/forum/list";
import { CommunityCard } from "../views/community/list";
import { CommunityCard as EventCard } from "../views/event/list";
import { useNavigate } from "react-router";
import { searchAllCommunities } from "../controllers/community/community";
import { searchAllEvents } from "../controllers/event/event";
import { SearchWidget } from "./search.widget";

export const ExploreList = () => {
    const userDetails = useContext(UserContext)
    let token = userDetails?.token ?? ''
    const [max_id, setMaxID] = useState(null)
    const { data:timeline, refetch } = useQuery({ queryKey: ['getPublicTimeline'], queryFn: () => publicTimeline(token, max_id) })
    const [media, setMedia] = useState([])

    const [title, setTitle] = useState('')
    let page_number = 0
    let limit = 2

    const { data:forums, refetch:refetchForum } = useQuery({ queryKey: ['searchAllForums'], queryFn: () => searchAllForums({token, title, page_number, limit}) })
    let allForums = forums?.forum ?? []

    const { data:communities, refetch:refetchCommunity } = useQuery({ queryKey: ['searchAllCommunities'], queryFn: () => searchAllCommunities({token, title, page_number, limit}) })
    let allCommunities = communities?.community ?? []

    const { data:events, refetch:refetchEvent } = useQuery({ queryKey: ['searchAllEvents'], queryFn: () => searchAllEvents({token, title, page_number, limit}) })
    let allEvents = events?.event ?? []

    const isInMedia = (id) => {
      let doesItExsit = media?.filter(x => x.id === id)
      return doesItExsit.length > 0 ?  true :  false
    }
    
    useEffect(() => {
      if(timeline?.length > 0){
        timeline?.filter(item => isInMedia(item.id) ? null : item.media_attachments.length > 0 ? setMedia(media => [...media, {img:item.media_attachments[0].url, id:item.id}]) : setMedia(media => [...media, {img:item.content, id:item.id}]))
      }
      refetch()
      refetchForum()
      refetchCommunity()
      refetchEvent()
    })
    
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
      if (bottom) { 
          setMaxID(timeline[timeline.length - 1].id)
      }
    }
    
    let navigate = useNavigate()
    let grid_style = {maxWidth:'97%', padding:0, margin:0}
    return (
      <>
        <SearchWidget search={title} setSearch={setTitle} />
        {allCommunities.length > 0 &&
        <>
        <Divider textAlign="left"><Chip label="New Communites" /></Divider>
        <br />
        <Grid container spacing={1} style={grid_style}>
          {allCommunities.map((community, i) =>
            <Grid key={i} item xs={6}>
              <CommunityCard card_details={community} navigate={navigate} />
            </Grid>
          )}
        </Grid>
        <Button onClick={() => navigate("/community/list")}>View All Communities</Button>
        </>
        }

        {allEvents.length > 0 &&
        <>
          <Divider textAlign="left"><Chip label="Latest Events" /></Divider>
          <br />
        <Grid container spacing={1} style={grid_style}>
          {allEvents.map((forum, i) =>
            <Grid key={i} item xs={6}>
              <EventCard card_details={forum} navigate={navigate} />
            </Grid>
          )}
        </Grid>
        <Button onClick={() => navigate("/event/list")}>View All Events</Button>
        </>
        }

        {allForums.length > 0 &&
        <>
        <Divider textAlign="left"><Chip label="Latest Topics" /></Divider>
        <br />
        <Grid container spacing={1} style={grid_style}>
          {allForums.map((forum, i) =>
            <Grid key={i} item xs={6}>
              <ForumCard card_details={forum} navigate={navigate} />
            </Grid>
          )}
        </Grid>
        <Button onClick={() => navigate("/forum/list")}>View All Topics</Button>
        </>
        }

        <Divider textAlign="left"><Chip label="Public Updates" /></Divider>
        <div onScroll={handleScroll}  style={{overflowY: 'scroll', maxHeight: '95vh', scrollbarWidth:'none'}}>
          <PostList media={media} />
        </div>
      </>
    )
}