import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { PostList } from "../../components/post/PostList"
import { Avatar, Button, Chip, CircularProgress, Grid } from "@mui/material"
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { getSingleAccount } from "../../controllers/accounts/accounts";
import { FollowAccountManager, MessageUser } from "../../components/account/AccountActions";

export const AccountShow = () => {
    const userDetails = useContext(UserContext)
    let token = userDetails?.token ?? ''
    let { id } = useParams()
    let navigate = useNavigate()
    const [max_id, setMaxID] = useState(null)
    const [media, setMedia] = useState([])
    const { data:account, refetch } = useQuery({ queryKey: ['getSingleAccount'], queryFn: () => getSingleAccount(id, token, max_id) })
   
    const isInMedia = (id) => {
        let doesItExsit = media?.filter(x => x.id === id)
        return doesItExsit.length > 0 ?  true :  false
    }

    useEffect(() => {
        account?.statuses?.filter(item => isInMedia(item.id) ? null : item.visibility !== "public" ? null : item.media_attachments.length > 0 ? setMedia(media => [...media, {img:item.media_attachments[0].url, avatar:null, id:item.id}]) : setMedia(media => [...media, ({img:item.content, avatar:null, id:item.id})]) )
        refetch()
    })

    useEffect(() => {
        setMedia([])
    }, [account?.account?.id, id])
    
    
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
      if (bottom) {
        if(account?.statuses?.length > 0){
            setMaxID(account?.statuses[account?.statuses?.length - 1].id)
        }
      }
    }
    
    return (
        <MainLoggedInLayout>
            {account?.account ?
            <Grid  container spacing={2} style={{padding:0, width:'100%'}}>
                <Grid item xs={5} style={{paddingLeft:10}}>
                    <Avatar sx={{ width: 75, height: 75, marginBottom:"5px" }} alt="Remy Sharp" src={account.account.avatar} />
                    
                    <br />
                    <Chip sx={{marginBottom:"5px", maxWidth:'300px'}} label={"@"+account?.account?.acct} variant="outlined" />
                    <br />
                    {userDetails?.id !== id ?
                        <>
                            <FollowAccountManager auth_id={userDetails?.id} id={id} token={token} />
                            <MessageUser token={token} user={account?.account?.acct} />
                        </>
                        :
                        <Button onClick={() => navigate("/settings")} variant="contained" style={{marginBottom:"5px"}}>Edit Profile</Button>
                    }
                </Grid>
                <Grid item xs={7} style={{textAlign:'right', paddingRight:10}}>
                    {account?.account?.display_name}
                    <div dangerouslySetInnerHTML={{__html: account.account.note}}></div>
                </Grid>
                <Grid item xs={12} style={{padding:0}}>
                    <div onScroll={handleScroll} style={{overflowY: 'scroll', maxHeight: '90%', scrollbarWidth:'none'}}>
                        <PostList media={media} />
                    </div>
                </Grid>
            </Grid>
            :
            <CircularProgress />
            }
        </MainLoggedInLayout>
    )
}